@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
strong.reset-strong {
  font-weight: normal !important; 
}
body{
  background: #f5f5f5;
}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.btn{
  background: #fff;
  color: #212121;
  padding: 10px 13px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
}
.container1{
  padding-left: 5%;
  padding-right: 5%;

}
.playfair-display-headingfont {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
